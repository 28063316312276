<template>
  <footer>
    <p>©{{footer_year}} — All Rights reserved by Ira Softwares</p>
  </footer>
</template>
<script>
export default {
  name: "Footer",

  data() {
    return {
      footer_year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
