<template>
  <div>
    <p>{{ permissionMessage }}</p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { getPermissionMessage } from '@/helpers/permissionHelper';
// User permission level (can come from API or state management)
const userPermission = ref(''); // Example: 'admin', 'editor', 'viewer'
// Call the helper function directly to get the message
const permissionMessage = getPermissionMessage(userPermission.value);
</script>
