<template>
  <header v-if="loginUser.type_uid == '1'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Administrator</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == '2'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Patient Manager</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == '3'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Assistant</h2>
    </div>
    <div class="haeder-right">
      <!-- <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span> -->
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == '11'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Superuser</h2>
    </div>
    <div class="haeder-right">
      <Common />
    </div>
  </header>
</template>
<script>
import Common from "./Common.vue";
import $ from "jquery";
import "../../../src/assets/css/style.css";
export default {
  name: "Header",
  components: {
    Common: Common,
  },
  data() {
    return {
      profileUrl: null,
      successMessage: null,
      loginUser: JSON.parse(localStorage.getItem("LogIn")),
    };
  },

  mounted() {
        if (!localStorage.getItem("LogIn")){
          this.$router.push("/login");
        }

        if(localStorage.getItem("LogIn")){
          const extension = JSON.parse(localStorage.getItem("LogIn")).extension;
          const dialer_status = extension !== "" ? "1" : "0";
          this.dialer_show = dialer_status;
    }
  },
  methods:{    
    toogleBar(){
      $("header").toggleClass("sidebarClone");
      $("nav").toggleClass("sidebarClone");
      $("main").toggleClass("sidebarClone");
      $("footer").toggleClass("sidebarClone");
    },
    toogleCall(){
      $(".sidAudioCall").toggleClass("sidebarDailerActive");
    },
    toogleVideoCall(){
      $(".sidVideoCall").toggleClass("sidebarVideoActive");
    },
  },
};
</script>
