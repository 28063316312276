<template>
  <div class="loginOuter">
    <div class="alert alert-danger" :class="{ 'alert-active': errorMessage != null }">
      <strong>Error!</strong> {{ errorMessage }}
    </div>
    <div class="loginBox">
      <div class="loginImageCol">
        <img alt="" src="../assets/img/login-image.jpg" />
      </div>
      <div class="loginFormCol">
        <div class="logo">
          <img alt="" src="../assets/img/lvc-logo.png"/>
        </div>
        <div class="title"><h2>Login</h2></div>
        <form class="loginField" @submit.prevent="submitForm">
          <div class="inputCol">
            <div
              class="loginFeild"
              :class="{ 'inccorect-feild': v$.user_name.$error }">
              <input
                type="text"
                v-model="formFields.user_name"
                class="custom-field"
              /><i class="bi bi-person feild-icon"></i
              ><label>Username<span class="danger">*</span></label>
            </div>
            <p class="danger error-show">Error! Username is Required.</p>
          </div>
          <div class="inputCol">
            <div
              class="loginFeild" :class="{ 'inccorect-feild': v$.password.$error }">
              <input
                type="password"
                v-model="formFields.password"
                class="custom-field"
              /><i class="bi bi-lock feild-icon"></i
              ><label>Password<span class="danger">*</span></label>
            </div>
            <p class="danger error-show">
              Error! Your password is missing or entered incorrectly.
            </p>
          </div>
          <!-- <div class="inputCol">
            <div class="loginFeild" >
              <input type="checkbox" v-model="rememberMe" />
              <label>&nbsp; Remember Me </label>
            </div>
          </div> -->
          <div class="customButton">
            <button class="login-button">
              Login <span class="bi bi-arrow-right"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie';
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();

export default {
  name: "Login",
  data() {
    return {
      rememberMe: false,
      userGroup: [
        { type_uid: 11, type_user: "Super Admin" }
      ],
      userType: [],
      errorMessage: null,
    };
  },
  setup() {
    const formFields = reactive({
      type_uid: 11,
      user_name: "",
      password: "",
    });

    const rules = {
      // type_uid: {
      //   required,
      // },
      user_name: {
        required,
      },
      password: {
        required,
      },
    };
    const v$ = useVuelidate(rules, formFields);
    return {
      formFields,
      v$,
    };
  },

  async created() {
    this.loadCredentials();
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =type_uid === 11? "ActivityLog": "superuser";
         this.$router.push({ name: redirction });
    }
  },
  methods: {
    hideError: function () {
      this.errorMessage = null;
    },
    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
          // const token = Math.floor(Math.random() * 10000);   // Normally obtained from your backend
          //   if (this.rememberMe) {
          //     this.saveCredentials(token);
          //   } else {
          //     this.clearCredentials();
          //   }
          try {
          const resData = await postData("login/adminLogin", this.formFields);
          console.log("Response", resData);
          if (resData.status === 200 && resData.data.statuscode === 1){
            // Superuser
            if (resData.data.result.type_uid === 11) {
              localStorage.setItem(
                "LogIn",
                JSON.stringify(resData.data.result)
              );
              this.$router.push({ name: "RequestDemo" });
            }
            } else {
            this.$router.push("superuser");
            this.errorMessage = resData.data.message;
            setTimeout(
              function () {
                this.hideError();
              }.bind(this),
              4000
            );
          }
        } catch (e) {
          this.error = e;
        }
      }
    },
    // saveCredentials(token) {
    //   Cookies.set('token', token, { expires: 7 });
    //   Cookies.set('type_uid', this.formFields.type_uid,{ expires: 7 }); // Store email for auto-fill (optional)
    //   Cookies.set('user_name', this.formFields.user_name,{ expires: 7 }); // Store email for auto-fill (optional)
    //   Cookies.set('password', this.formFields.password,{ expires: 7 }); // Store email for auto-fill (optional)
    // },

    // loadCredentials() {
    //   const type_uid = Cookies.get('type_uid');
    //   const user_name = Cookies.get('user_name');
    //   const password = Cookies.get('password');
    //   const token = Cookies.get('token');
      
    //   if (token) {
    //     console.log('Token found. Auto-login using token.');
    //   }
    //   if(password) {
    //     this.formFields.type_uid=type_uid;
    //     this.formFields.user_name=user_name;
    //     this.formFields.password = password;
    //     this.rememberMe = true; 
    //   }
    // },
    // clearCredentials() {
    //   Cookies.remove('token');
    //   Cookies.remove('type_uid');
    //   Cookies.remove('user_name');
    //   Cookies.remove('password');
    // }

  },
};
</script>
<style scoped>
.hClass {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999393;
  line-height: 0.1em;
  margin: 18px 0 22px;
}
.hClass span {
  background: #fff;
  padding: 0 10px;
}
</style>
