<template>
  <div class="marquee-container" >
    <p class="marquee">
      <span>This is a limited demonstration of Assistant Desk application. The demo version
        restricts the editing features. Contact us to use all the 
        features.</span>
        <span>This is a limited demonstration of Assistant Desk application. The demo version
        restricts the editing features. Contact us to use all the 
        features.</span>

        <span>This is a limited demonstration of Assistant Desk application. The demo version
        restricts the editing features. Contact us to use all the 
        features.</span>

        <span>This is a limited demonstration of Assistant Desk application. The demo version
        restricts the editing features. Contact us to use all the 
        features.</span>

        <span>This is a limited demonstration of Assistant Desk application. The demo version
        restricts the editing features. Contact us to use all the 
        features.</span>
    </p>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "HeadingPage",
  data() {
    return {
      //mdate:new Date(),
    };
  },
  // methods: {
  //   newFormat(m){
  //   return moment().format('MM/DD/YYYY hh:mm A');
  // },

  // },
};
</script>
<style>
.marquee {
  background-color: red;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 0;
}
.marquee span {
  display: inline-block;
  font-size: 20px;
  position: relative;
  left: 100%;
  animation: marquee 30s linear infinite;
  color: #fff;
}
.marquee:hover span {
  animation-play-state: paused;
}

.marquee span:nth-child(1) {
  animation-delay: 0s;
}
.marquee span:nth-child(2) {
  animation-delay: 0.8s;
}
.marquee span:nth-child(3) {
  animation-delay: 1.6s;
}
.marquee span:nth-child(4) {
  animation-delay: 2.4s;
}
.marquee span:nth-child(5) {
  animation-delay: 3.2s;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
</style>
