<template>
  <div
    class=""
    style="
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;">
    <div class="toggle-option" v-if="typeuid===1||typeuid===2">
      <div data-bs-toggle="dropdown" aria-expanded="false">
        <span style="position: relative;"><i class="bi bi-bell"></i> <b class="notification_number">{{ notificaitonCount }}</b></span>
      </div>
      <ul class="dropdown-menu notification-dropdown" aria-labelledby="dropdownMenuButton2">
        <li>Notifications</li>
        <li v-for="(res,index) in limitedItems" :key="index" :v-if="index">{{ res.content }} <label @click="readNotifications(res.uid)">X</label>
        </li>
        <li v-if="typeuid===1" ><router-link :to="'/admin/allnotification'"><u>See Notifications</u></router-link></li>
        <li v-if="typeuid===2"><router-link :to="'/manager/notification'"><u>See Notifications</u></router-link></li>
       </ul>
    </div>
    <div class="toggle-option" v-if="typeuid===11">
      <div data-bs-toggle="dropdown" aria-expanded="false">
        <span style="position: relative;"><i class="bi bi-bell"></i> <b class="notification_number">{{ notificaitonCount1 }}</b></span>
      </div>
      <ul class="dropdown-menu notification-dropdown" aria-labelledby="dropdownMenuButton2">
        <li>Notifications</li>
        <li v-for="(res,index) in limitedItems1" :key="index" :v-if="index">{{ res.content }} <label @click="readNotifications(res.uid)">X</label>
        </li>
        <li v-if="typeuid===11" ><router-link :to="'/notifications'"><u>See Notifications</u></router-link></li>
       </ul>
    </div>
    <div class="profile-dropdown">
      <div data-bs-toggle="dropdown" aria-expanded="false" class="pr-name">
        <span class="profile-img"><i class="bi bi-person-circle"></i></span>
        <span class="us-name">{{ userName }}</span>
      </div>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-if="typeuid===1||typeuid===2||typeuid===3">
          <router-link
            :to="{ name: profileUrl }"
            class="dropdown-item" style="cursor: pointer"
            ><i class="bi bi-person"></i> Profile</router-link>
        </li>
        <li>
          <a
            class="dropdown-item"
            style="cursor: pointer"
            v-on:click="userLogout"
            ><i class="bi bi-box-arrow-right"></i> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import callService from "@/services/Service";
const { getData, getByID, postData,putData } = callService();
export default {
  name: "Common",
  data() {
    return {
      intervalId: null,
      typeuid:null,
      notificaitonCount: null,
      notificaionArr: [],
      notificaitonCount1: null,
      notificaionArr1: [],
      userName: JSON.parse(localStorage.getItem("LogIn")).user_fname,
      profileUrl: null,
    };
  },
  async mounted() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction = type_uid === 11? "ActivityLog":type_uid === 1? "AdminProfile": type_uid === 2? "ManagerProfile": type_uid === 3? "Profile": "/login";
      this.typeuid=type_uid;
      this.profileUrl = redirction;
    }
  },
  created() {
    this.getNotification();
    if(JSON.parse(localStorage.getItem("LogIn")).user_uid && ((JSON.parse(localStorage.getItem("LogIn")).type_uid==1)||(JSON.parse(localStorage.getItem("LogIn")).type_uid==2))){
      this.intervalId = setInterval(this.getNotification,4000); // 5000ms = 20 seconds
      console.log("1");
    }
   // Admin notification
    this.getNotification1();
    if(JSON.parse(localStorage.getItem("LogIn")).user_uid && (JSON.parse(localStorage.getItem("LogIn")).type_uid===11)){
      this.intervalId = setInterval(this.getNotification1,4000); // 5000ms = 20 seconds
      console.log("2");
    }
  },
  
  computed: {
    limitedItems() {
      return this.notificaionArr.slice(0,4);
    },
     limitedItems1() {
      return this.notificaionArr1.slice(0,4);
    }
  },
  methods: {
    async readNotifications1(uid){
      const resData = await postData("user/updateNotification/",{uid:uid});
    },
    async getNotification1(){
      try {
      const notificationResponse = await postData("user/getNotification",{type_uid:11});
      if (notificationResponse.status === 200 && notificationResponse.data.statuscode === 1){
        this.notificaionArr1 = notificationResponse.data.result;
        this.notificaitonCount1 = notificationResponse.data.result.length;
      }
    } catch (e) {
      this.error = e;
    }
    },

    async readNotifications(uid){
      const resData = await postData("user/updateNotification/",{uid:uid});
    },
    async getNotification(){
      try {
      const notificationResponse = await postData("user/getNotification",{type_uid:this.typeuid});
      if (notificationResponse.status === 200 && notificationResponse.data.statuscode === 1){
        this.notificaionArr = notificationResponse.data.result;
        this.notificaitonCount = notificationResponse.data.result.length;
        //console.log("detail",this.notificaionArr);
      }
    } catch (e) {
      this.error = e;
    }
    },
    userLogout() {
      localStorage.clear();
      this.$router.push({ name: "login" });
      //this.updateLogoutStatus();
    },
    async updateLogoutStatus() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      try {
        const resData = await postData("login/updateLoginStatus", {
          user_uid: user_uid,
          login_status: 0,
        });
        if (resData.status === 200 && resData.data.statuscode === 1) {
          localStorage.clear();
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$router.go();
            });
        }
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>


